

import { format, parseISO, parse } from "date-fns";
import { Vue, Component, Watch } from "vue-property-decorator";

import { CycleService } from "@/services/cycle";
import { CycleDTO } from "@/services/cycle/cycle.dto";

interface TableHeader {
  text: string;
  value: string;
}

@Component
export default class Cycles extends Vue {
  loading = false;
  cycles: CycleDTO[] = [];
  selectedCycle!: CycleDTO;
  showActivateCycleDialog = false;
  showCreateCycleDialog = false;
  tempCycleName = "";
  tempAssignDate = "";
  tempDueDate = "";
  assignDateMenu = false;
  dueDateMenu = false;
  tempIsActive = false;
  headers: TableHeader[] = [
    {
      text: "Cycle Name",
      value: "name"
    },
    {
      text: "Start Date",
      value: "assignedOn"
    },
    {
      text: "End Date",
      value: "dueOn"
    },
    {
      text: "Status",
      value: "status"
    },
    {
      text: "",
      value: "action"
    }
  ];

  data() {
    return {};
  }

  created() {
    this.$store.commit("liveData/setSelectedActivity", 5);
    this.$store.commit("liveData/setSelectedActivityName", "Cycles");
    this.getCycles();
  }

  @Watch("tempAssignDate")
  onTempAssignDateChange() {
    this.setTempCycleName();
  }

  @Watch("tempDueDate")
  onTempDueDateChange() {
    this.setTempCycleName();
  }

  setTempCycleName() {
    if (this.tempAssignDate && this.tempDueDate) {
      this.tempCycleName = [
        format(
          parse(this.tempAssignDate, "yyyy-MM-dd", new Date()),
          "dd-MMM-yyyy"
        ),
        "To",
        format(parse(this.tempDueDate, "yyyy-MM-dd", new Date()), "dd-MMM-yyyy")
      ].join(" ");
    }
  }

  updateCycle(cycle: CycleDTO) {
    this.$store.commit("CycleStore/setCycle", cycle);
    window.localStorage.setItem("currentCycle", JSON.stringify(cycle));
  }

  async getCycles() {
    try {
      this.cycles = await CycleService.getCycles();
    } catch (err) {
      console.log(err);
    }
  }

  openActivateCycleDialog(cycle: CycleDTO) {
    this.selectedCycle = cycle;
    this.showActivateCycleDialog = true;
  }

  openCreateCycleDialog() {
    this.assignDateMenu = false;
    this.dueDateMenu = false;
    this.tempAssignDate = "";
    this.tempDueDate = "";
    this.tempCycleName = "";
    this.showCreateCycleDialog = true;
  }

  closeCreateCycleDialog() {
    this.assignDateMenu = false;
    this.dueDateMenu = false;
    this.tempAssignDate = "";
    this.tempDueDate = "";
    this.tempCycleName = "";
    this.showCreateCycleDialog = false;
  }

  async activateCycle() {
    try {
      await CycleService.updateCycle(this.selectedCycle.cycleId, true);
      this.cycles.forEach((cycle: CycleDTO) => (cycle.isActive = false));
      this.cycles[this.cycles.indexOf(this.selectedCycle)].isActive = true;
      this.showActivateCycleDialog = false;
    } catch (err) {
      console.log(err);
    }
  }

  async createCycle() {
    try {
      const createdCycle: CycleDTO = await CycleService.createCycle({
        name: this.tempCycleName,
        assignedOn: parse(this.tempAssignDate, "yyyy-MM-dd", new Date()),
        dueOn: parse(this.tempDueDate, "yyyy-MM-dd", new Date()),
        isActive: this.tempIsActive
      });
      if (this.tempIsActive) {
        this.cycles.forEach((cycle: CycleDTO) => (cycle.isActive = false));
      }
      this.cycles.push(createdCycle);
      this.showCreateCycleDialog = false;
    } catch (err) {
      console.log(err);
    }
  }

  formatDate(date: string | undefined) {
    return date ? format(parseISO(date), "dd-MM-yyyy") : "";
  }
}
